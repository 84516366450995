import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./contact.css"

const ContactPage = () => (
  <Layout>
    <SEO title="Yhteystiedot" />
    <div className="center">
      <div className="content-block">
        <div style={{ alignSelf: "flex-start" }}>
          <h2>Yhteystiedot</h2>
          <p>Kasvu Valmennus Group Oy</p>
          <p>
            Mikäli tarpeeseenne sopivaa tuotetta ei löydy vielä
            verkkosivuiltamme, olethan yhteydessä meihin sähköpostitse
            osoitteeseen info@hankintaverkko.fi
          </p>
        </div>

        <div>
          {/* <form method="post" action="#">
            <div>
              <input type="text" name="name" id="name" placeholder="Nimi" />

              <input
                type="text"
                name="company"
                id="company"
                placeholder="Yritys"
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Sähköposti"
              />

              <input
                type="text"
                name="phone"
                id="phone"
                placeholder="Puhelin"
              />
            </div>
            <div>
              <textarea
                name="message"
                id="message"
                rows="5"
                placeholder="Miten voimme auttaa"
              />
            </div>

            <button type="submit" className="btn">
              Ota yhteyttä
            </button>
          </form> */}
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactPage
